import React from 'react';
import { Routes, Route } from 'react-router-dom';
import './css/style.css'
import Home from './pages/Home'
import About from './pages/About';
import Contact from './pages/Contact';
import Signup from './pages/Signup';
import Login from './pages/Login';
import Digitize from './pages/Digitize';
import ForgotPassword from './pages/Forgot-Password';

const App = () => {

  const [loggedIn, setLoggedIn] = React.useState(false);
  const BACKEND_URL = 'https://5c3m0d1pf1.execute-api.us-east-1.amazonaws.com/main/auth';

  return (
    <div>
      <Routes>
        <Route
            path="/"
            element={
              <Home backend_url={BACKEND_URL} loggedIn={loggedIn} setLoggedIn={setLoggedIn} /> 
            } />
        <Route 
            path="/about"
            element={<About loggedIn={loggedIn} setLoggedIn={setLoggedIn} /> 
            }/>
          <Route 
            path="/contact"
            element={<Contact loggedIn={loggedIn} setLoggedIn={setLoggedIn}/>} />
          <Route 
            path="/signup"
            element={
              <Signup backend_url={BACKEND_URL} loggedIn={loggedIn} setLoggedIn={setLoggedIn} />
            } />
          <Route 
            path="/login"
            element={
              <Login backend_url={BACKEND_URL} loggedIn={loggedIn} setLoggedIn={setLoggedIn} />
            } />
          <Route
            path="/digitize"
            element={
              <Digitize loggedIn={loggedIn} setLoggedIn={setLoggedIn} />
            } />
          <Route
            path='/forgot-password'
            element={
              <ForgotPassword />
            } />
      </Routes>
    </div>
  );
}

export default App;
