import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

const Contact = ({loggedIn, setLoggedIn}) => {
    return (
        <div>
            <Navbar
                contact={false}
                loggedIn={loggedIn}
                setLoggedIn={setLoggedIn}
            />
            <div className="section mt-80">
                <div className="container">
                    <form method="post" action="#" className="text-center contactform">
                        <h1 className="text-center">Contact Us</h1>

                        <div>
                            <input type="name" name="mail" className="tbox" placeholder="Name" />
                        </div>
                        <div>
                            <input type="email" name="mail" className="tbox" placeholder="Email" />
                        </div>
                        <div>
                            <textarea type="email" name="mail" className="tbox" placeholder="Message"></textarea>
                        </div>
                        <div className="mt-25 text-center">
                            <input className="btn" type="submit" name="submit" value="Send" />
                        </div>
                    </form>
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default Contact