import React from 'react'
import axios from 'axios'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

const Signup = ({ backend_url, loggedIn, setLoggedIn }) => {
    const [email, setEmail] = React.useState("");
    const [result, setResult] = React.useState("");
    axios.defaults.baseURL = backend_url
    
    React.useEffect(() => {
        let tokenAvailable = localStorage.getItem("login-token");

        if (tokenAvailable !== null) {
            setLoggedIn(true);
        }

    }, [loggedIn]);
    return (
        <div>
            <Navbar 
                loggedIn={loggedIn} 
                setLoggedIn={setLoggedIn} 
                signup={false}
            />
            <div className="section bg">
                <div className="container">
                    <div className="two-col">
                        <div></div>
                        <div className="form-col">
                            <form method="post" action="#">
                                <h1 className="text-center">Welcome</h1>
                                <div>
                                    <input type="email" name="mail" className="tbox" placeholder="Enter Email..." />
                                </div>

                                <div className="mt-25 text-center">
                                    <input type="submit" name="submit" value="Signup" className="btn" />
                                </div>
                                <div className="mt-25 text-center">
                                    <b>Or</b>
                                </div>
                                <div className="login-box">
                                    <a href="#" className="social-button" id="facebook-connect"> <span>Facebook</span></a>
                                    <a href="#" className="social-button" id="google-connect"> <span>Google</span></a>
                                    <a href="#" className="social-button" id="twitter-connect"> <span>Twitter</span></a>
                                    <a href="#" className="social-button" id="linkedin-connect"> <span>LinkedIn</span></a>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Signup