import React from 'react'
import { Link } from 'react-router-dom'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { validateToken } from '../utils'
import AdComponent from '../components/Ads'

const Home = ({ loggedIn, setLoggedIn }) => {

    var tokenIsValid = null

    React.useEffect(() => {
        isLoggedIn();
    }, [loggedIn]);

    const isLoggedIn = async () => {
        tokenIsValid = await validateToken(localStorage.getItem("login-token"));

        if (tokenIsValid) {
            setLoggedIn(true);
        }
    }

    return (
        <div>
            <h2><AdComponent /></h2>
            <Navbar 
                signup
                login
                about
                loggedIn={loggedIn}
                setLoggedIn={setLoggedIn}
            />
            <div className="section mt-80">
                <div className="container">
                    <iframe className="homeiframe" src="https://www.youtube.com/embed/WJ04husUWqg" title="Vhs insert (Read Desc)" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>

                    <div className="text-center mt-25">
                        {tokenIsValid ? <Link to="/digitize" className="btn"> Digitize Now!</Link> : <Link to="/login" className='btn'> Digitize Now! </Link>}
                    </div>
                </div>
            </div>

            <Footer />

        </div>
    );
}

export default Home
