import React from 'react'
import axios from 'axios'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { Link, useNavigate } from 'react-router-dom'

const Login = ({ backend_url, loggedIn, setLoggedIn}) => {
    const navigate = useNavigate();
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [result, setResult] = React.useState("");
    axios.defaults.baseURL = backend_url

    React.useEffect(() => {
        let tokenAvailable = localStorage.getItem("login-token");

        if (tokenAvailable !== null) {
            setLoggedIn(true);
        }

    }, [loggedIn]);

    return (
        <div>
            <Navbar
                loggedIn={loggedIn}
                setLoggedIn={setLoggedIn}
                login={false}
            />
            <div className="section bg">
                <div className="container">
                    <div className="two-col">
                        <div></div>
                        <div className="form-col">
                            <form method="post" action="#">
                                <h1 className="text-center">LOGIN</h1>
                                <div>
                                    <input type="email" name="mail" className="tbox" placeholder="Enter Email..." />
                                </div>
                                <div>
                                    <input type="password" name="pass" className="tbox" placeholder="Enter Password..." />
                                </div>
                                <div className="mt-25 text-center">
                                    <Link to ="/forgot-password"> Forgot Password </Link>
                                </div>
                                <div className="mt-25 text-center">
                                    <input type="submit" name="submit" value="Login" className="btn" />
                                    {/* HERE consider asking user if they dont have an account to signup and provide a button */}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Login