import React from 'react'
import {Link, Navigate, useNavigate} from 'react-router-dom'
import logo from '../imgs/logo.png'
import { validateToken } from '../utils'

const Navbar = ({contact=true, about=true, signup=true, login=true, loggedIn, setLoggedIn}) => {
    // TODO fix this
    function openmenu(){
        //$('.nav').toggle();
        console.log("Open");
    }
    const navigate = useNavigate();
    var tokenIsValid = null

    React.useEffect(() => {
        isLoggedIn();
    }, [loggedIn]);

    const isLoggedIn = async () => {
        tokenIsValid = await validateToken(localStorage.getItem("login-token"));

        if (tokenIsValid) {
            setLoggedIn(true);
        }
    }

    const logout = async () => {
        localStorage.removeItem("login-token");
        setLoggedIn(false);
        navigate("/");
    }
    return (
        <header>
            <div className="container">
                <div className="header-flex">
                    <div className="logo">
                        <Link to ="/"><img src={logo} alt="" /></Link>
                    </div>
                    <div className="nav">
                        <ul>
                            {about ? <Link to="/about"><li>About</li></Link> : null}
                            {signup && !tokenIsValid ? <Link to="/signup"><li>Signup</li></Link> : null}
                            {login && !tokenIsValid ? <Link to="/login"><li>Login</li></Link> : null}
                            {tokenIsValid ? <li onClick={logout}>Logout</li> : null}
                            {contact ? <Link to="/contact" className='btn'><li>Contact</li></Link> : null}

                        </ul>
                    </div>
                    {/* TODO fix this */}
                    <div className="mobile"><i className="fa fa-bars" aria-hidden="true" onClick={openmenu}></i></div>
                </div>
            </div>
        </header>
    );
}

export default Navbar