import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import AdComponent from '../components/Ads'

const About = ({loggedIn, setLoggedIn}) => {
    return (
        <div>
            <h1>testing testing ?  ? </h1>
            <h2><AdComponent /></h2>
            <Navbar
                about={false}
                loggedIn={loggedIn}
                setLoggedIn={setLoggedIn}
            />

            <div className="section mt-80">
                <div className="container">
                    <h1>About Us</h1>
                    <p>After years of being hoe'd at work these GOAT came to graze on </p>
                </div>
            </div>

            <Footer />
        </div>
    )
}
export default About