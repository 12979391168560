import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

const ForgotPassword = () => {
    return (
        <div>
            <Navbar />
            <div className="section bg">
                <div className="container">

                    <div className="two-col">
                        <div></div>
                        <div className="form-col">
                            <form method="post" action="#">
                                <h1 className="text-center">Restore Password</h1>
                                <div>
                                    <input type="email" name="mail" className="tbox" placeholder="Enter Email..." />
                                </div>

                                <div className="mt-25 text-center">
                                    <input type="submit" name="submit" value="Request Reset Link" className="btn" />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ForgotPassword