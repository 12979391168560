import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

const Digitize = ({loggedIn, setLoggedIn}) => {
    return (
        <div>
            <Navbar 
                loggedIn={loggedIn}
                setLoggedIn={setLoggedIn}
            />
            <div className="section mt-80">
                <div className="container">
                    <form className="form-container" enctype='multipart/form-data'>
                        <div className="upload-files-container">
                            <div className="drag-file-area">
                                <span className="material-icons-outlined upload-icon"> File_Upload </span>
                                <h3 className="dynamic-message"> Drag and drop any file here </h3>
                                <label className="label"> or <span className="browse-files"> <input type="file" className="default-file-input" /> <span className="browse-files-text">Browse files</span> <span>from device</span> </span> </label>
                            </div>
                            <span className="cannot-upload-message"> <span className="material-icons-outlined">error</span> Please select a file first <span className="material-icons-outlined cancel-alert-button">cancel</span> </span>
                            <div className="file-block">
                                <div className="file-info"> <span className="material-icons-outlined file-icon">description</span> <span className="file-name"> </span> | <span className="file-size">  </span> </div>
                                <span className="material-icons remove-file-icon">delete</span>
                                <div className="progress-bar"> </div>
                            </div>
                            <button type="button" className="upload-button"> Upload </button>
                        </div>
                    </form>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Digitize