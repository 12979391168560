import axios from "axios";

export const validateToken = async (token, backend_url) => {
    axios.defaults.baseURL = backend_url

    // checks if token was null, return false
    if (!token) {
        return false;
    }

    try {
        const headers = {
            "Accept": "application/json",
            "Authorization": `Bearer ${token}`,
        }
        const reqOptions = {
            url: "/api/v1/user/details",
            method: "GET",
            headers: headers
        }
        const res = await axios.request(reqOptions);
        const data = res.data;

        return true;

    } catch (err) {
        localStorage.removeItem("login-token");

        return false;
    }
}

export const logout = () => { localStorage.removeItem("login-token") };
